import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import "./buttonIcon.scss"

const ButtonIcon = props => {
    const { children, onClick } = props;
    const [isClicked, setClicked] = useState(false);
    const handleClick = () => {
        setClicked(!isClicked);
        onClick();
    };
    return (
        <button
            className={classNames("ButtonIcon", {
                "is-clicked": isClicked === true,
            })}
            onClick={()=> handleClick()}
        >
            {children}
        </button>
    )
};

ButtonIcon.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
};

ButtonIcon.defaultProps = {
    onClick: undefined,
};

export default ButtonIcon