import React from "react"
import ImgBack from "../../images/back.png"
import "./cardBack.scss"

const CardBack = () => {
  return (
    <img
      className="CardBack"
      src={ImgBack}
      alt="39 W Elm St, Hopkinton MA,01748"
    />
  )
};

export default CardBack
