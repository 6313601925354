/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import Header from "../Header/Header";
import Main from "../Main/Main";
import PrintBanner from "../PrintBanner/PrintBanner";
import "./layout.scss";
import PropTypes from "prop-types";

const Layout = (props) => {
  const {uId} = props;
  return (
    <>
      <Header>
        The Gavula Family <span className="is-gray">|</span> 2019
      </Header>
      <Main uId={uId}/>
      <PrintBanner uId={uId}/>
    </>
  );
};

Layout.propTypes = {
    uId: PropTypes.number,
};

Layout.defaultProps = {
    uId: undefined,
};

export default Layout;
