import React from "react";
import PropTypes from "prop-types";
import PrintableFile from "../../downloads/GavulaXMas--2019.pdf";
import IconDownload from "../Icon/IconDownload/IconDownload";
import "./printBanner.scss";
import { get } from "../../utils/get.js";

const PrintBanner = props => {
  const { file, uId } = props;
  return (
    <section className="PrintBanner">
      <a
        href={file}
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => {
          get(`static/log/LogEvent.php?u=${uId}&e=print`);
        }}
      >
        <div className="PrintBanner-inner">
          Wish you had a printed copy? <IconDownload />
        </div>
      </a>
    </section>
  );
};

PrintBanner.propTypes = {
  file: PropTypes.string,
  uId: PropTypes.number,
};

PrintBanner.defaultProps = {
  file: PrintableFile,
  uId: undefined,
};

export default PrintBanner;
