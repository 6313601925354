import React from "react"
import ImgFront from "../../images/front.png"
import "./cardFront.scss"

const CardFront = () => {
  return (
    <img
      className="CardFront"
      src={ImgFront}
      alt="Cora and Lily at sunrise"
    />
  )
};

export default CardFront
