import React, { useState, useEffect } from "react";
import classNames from "classnames";
import CardBack from "../CardBack/CardBack";
import CardFront from "../CardFront/CardFront";
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import IconFlip from "../Icon/IconFlip/IconFlip";
import { get } from "../../utils/get";
import "./card.scss";
import PropTypes from "prop-types";

const Card = props => {
  const { uId } = props;
  const [isFlipped, setFlipped] = useState(true);

  const handleHover = () => {
    setFlipped(!isFlipped);
  };
  useEffect(() => {
    setFlipped(!isFlipped);
  }, []);
  return (
    <div className="Card">
      <ButtonIcon
        onClick={() => {
          handleHover();
          get(`static/log/LogEvent.php?u=${uId}&e=flip-card`);
        }}
      >
        <IconFlip />
      </ButtonIcon>
      <div
        className={classNames("Card-target", {
          "is-flipped": isFlipped === true,
        })}
      />
      <div className="Card-flipper">
        <CardFront />
        <CardBack />
      </div>
    </div>
  );
};
Card.propTypes = {
  uId: PropTypes.number,
};

Card.defaultProps = {
  uId: undefined,
};
export default Card;
