import React, { useState, useLayoutEffect, useRef } from "react";
import debounce from "lodash/debounce";
import classNames from "classnames";
import Card from "../Card/Card";
import "./main.scss";
import { get } from "../../utils/get";
import PropTypes from "prop-types";

const Main = props => {
  const { uId } = props;
  //
  const XMLHttpRequest = require("xhr2");
  const xhr = new XMLHttpRequest();
  //
  const [isMessageReveled, setMessageReveled] = useState(false);
  const [isStrikethrough, setStrikethrough] = useState(false);
  const message = useRef();

  useLayoutEffect(() => {
    const calcMessageReveal = () => {
      const pageYOffset = window.pageYOffset;
      const pageInnerHeight = window.innerHeight;
      const messageYOffset = message.current.getBoundingClientRect().top;

      if (messageYOffset < pageYOffset + pageInnerHeight) {
        setMessageReveled(true);
      } else {
        setMessageReveled(false);
      }
    };

    const scrollEventDebouncer = debounce(() => {
      calcMessageReveal();
    }, 100);

    if (typeof window !== "undefined") {
      window.addEventListener("load", calcMessageReveal());
      window.addEventListener("scroll", scrollEventDebouncer);
      window.addEventListener("resize", scrollEventDebouncer);
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", calcMessageReveal());
        window.removeEventListener("scroll", scrollEventDebouncer);
        window.removeEventListener("resize", scrollEventDebouncer);
      }
    };
  }, []);

  return (
    <main className="Main">
      <div className="Main-card">
        <div className="Main-cardInner">
          <Card uId={uId} />
        </div>
      </div>
      <div className="Main-message">
        <div
          ref={message}
          className={classNames("Main-messageInner", {
            "is-visible": isMessageReveled === true,
          })}
        >
          Greetings!
          <br />
          <p>
            Before you roll your eyes at this digital card take a moment to
            appreciate its virtues. It's homemade. No postal workers were hurt
            in its delivery. Perhaps most importantly, it arrived the same day
            it was sent!
          </p>
          <p>If you are still waiting for our 2018 card...sorry. We blew it.</p>
          <p>
            It's hard to believe how big the kids are getting. Cora turned 7 in
            April and started the 2nd grade last fall. Lily (5 last May) joins
            her on the bus each morning on her way to Kindergarten.
          </p>
          <p>
            When they are not at school they enjoy playing, drawing and waiting
            for the next big holiday, birthday, and/or lost tooth. It's still a
            precious age filled with hugs and laughter and maybe some tantrums
            and doors slamming. But the hugs... the hugs are great.
          </p>
          <p>
            John continues to contract with Liberty Mutual and Jenna with Bose.{" "}
            <span
              className={classNames({
                "is-strikethrough": isStrikethrough === true,
              })}
            >
              In their free time they published a series of award winning books
              on parenting. John cycled across Massachusetts on a hover-bike and
              Jenna got her black belt in Yoga.
            </span>
          </p>
          <p>
            <button
              className="ButtonLink"
              onClick={() => {
                setStrikethrough(!isStrikethrough);
                get(`static/log/LogEvent.php?u=${uId}&e=fact-check`);
              }}
            >
              Wow, really? Click here to fact check.
            </button>
          </p>
          <p>
            Well, we really did move in 2019. After 8 years of saving we finally
            bought a detached home right here in Hopkinton. It's a lovely
            colonial with a few cozy additions situated on 2+ acres of
            handsomely landscaped property. That John now mows.
          </p>
          <p>
            We're looking forward to our first Christmas here and to sharing the
            holiday with friends and family. We are especially grateful to share
            it with John's mother - who has courageously battled back from
            cancer! Amazing.
          </p>
          <p>
            On that high note we leave you with best wishes for the holiday
            season and happy new year!
          </p>
          <p>- John, Jenna, Cora and Lily</p>
        </div>
      </div>
    </main>
  );
};

Main.propTypes = {
  uId: PropTypes.number,
};

Main.defaultProps = {
  uId: undefined,
};
export default Main;
